import styled from 'styled-components';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  overflow: hidden;

  background-color: var(--white);
  color: var(--black);

  @media screen and (max-width: 600px) {
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 50rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  padding: 1rem 3rem;
  margin-top: 2rem;
  
  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: unset;
  }
`;

export const Label = styled.label`
  margin-bottom: 1rem;

  font-size: 1.125rem;
  font-weight: 500;
`;

export const Button = styled.button`
  cursor: pointer;

  width: 100%;
  min-height: 3rem;
  max-height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: .5rem 2rem;

  border: none;
  border-radius: .25rem;
  outline: none;

  background-color: var(--rancheiro-brown);
  color: var(--white);

  font-weight: 500;
  font-size: 1.125rem;

  &:hover {
    opacity: .9;
  }

  &:disabled {
    opacity: .7;
  }
`;

export const DropDown = styled.select`
  cursor: pointer;

  width: 100%;
  min-height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: .5rem;

  margin-bottom: 1rem;
  border: none;
  border-radius: .25rem;
  outline: none;

  background-color: var(--light);
  color: var(--dark);

  font-weight: bold;

  &:focus {
    outline: none;
  }
`;

export const Field = styled.input`
  cursor: pointer;

  width: 100%;
  min-height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: .5rem;

  margin-bottom: 1rem;
  border: none;
  border-radius: .25rem;
  outline: none;

  background-color: var(--light);
  color: var(--dark);

  &:focus {
    outline: none;
  }
`;

export const ComboBoxOption = styled.option`
`;

export const LocationStatusSection = styled.section`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: .25rem;

  color: var(--light);
`;

export const CheckCircleIcon = styled(FiCheckCircle)`
  color: var(--light);
`;

export const XCircle = styled(FiXCircle)`
  color: var(--light);
`;

export const CheckText = styled.span`
  margin-left: .75rem;

  font-size: 1.5rem;
  font-weight: 500;
`;