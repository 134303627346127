import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import * as Styles from './styles';

interface Props {
  size: number,
  color: string
}

const Loading: React.FC<Props> = ( { size, color, ...rest } ) => {
  return (
    <Styles.Container>
      <CircularProgress
        style={{
          ...rest,
          color: color,
          padding: 8,
          width: size,
          height: size
        }}
      />
    </Styles.Container>
  );
};

export default Loading;