import React from 'react';

import * as Styles from './styles';

interface Props {
  image: any;
  alt: string;
};

const SvgIllustration: React.FC<Props> = ({ image, alt }) => {
  return (
    <Styles.Container src={image} alt={alt} />
  );
};

export default SvgIllustration;