import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './Pages/Home';
import Finish from './Pages/Finish';
import PageNotFound from './Pages/PageNotFound';
import Teste from './Pages/Teste';

const CustomRoutes: React.FC = () => (
  <Routes>
    <Route path='/'       element={<Home/>} />
    <Route path='/home'   element={<Home/>} />
    <Route path='/finish' element={<Finish/>} />
    <Route path='/teste'  element={<Teste/>} />
    <Route path='/*'      element={<PageNotFound/>} />
  </Routes>
);

export default CustomRoutes;