import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import PageNotFound from '../PageNotFound';

// Utils
import { protheusApi } from '../../utils/api';
import { getFormattedDateAndTime } from '../../utils/customFormatters';
import useGeolocation from '../../utils/useGeolocation';

// Assets
import mapImage from '../../assets/map.svg';
import SvgIllustration from '../../components/SvgIllustration';

// Styles
import * as Styles from './styles';

const Home: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, position, error] = useGeolocation();

  const [isAuthorized, setAuthorizedStatus] = useState<boolean>(false);
  const [isLoading, setLoading]             = useState<boolean>(true);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [statusObs, setStatusObs]           = useState<string>('');
  const [hash, setHash]                     = useState<string>('');
  const [locationStatus, setLocationStatus] = useState<string>('loading');
  const [buttonLoading, setButtonLoading]   = useState<boolean>(false);
  
  const tripStatusOptions = [
    {id: 'O', description: 'Tudo ok. Estou seguindo viagem conforme combinado 😁'},
    {id: 'Q', description: 'Quebrado. Estou resolvendo algum problema no caminhão ☹️'},
    {id: 'P', description: 'Parado. Estou com problema pessoal ou na entrega 😐'},
    {id: 'L', description: 'Operador Logistico. Deixei a carga no operador logístico'},
    {id: 'A', description: 'Atrasado. Estou seguindo mas não vou cumprir a data combinada 😕'},
    {id: 'E', description: 'Entregue. Finalizei minha entrega e tudo correu 100% 🤩'}
  ];

  const handleSubmit: Function = (event: React.FormEvent<HTMLFormElement>) => {
    setButtonLoading(true);

    const { protheusDate, protheusTime } = getFormattedDateAndTime();

    protheusApi
    .post('incluistatus', {
      hash: hash,
      data: protheusDate,
      hora: protheusTime,
      latitude: position.latitude,
      longitude: position.longitude,
      status: selectedStatus,
      obs: statusObs
    })
    .then(res => {
      if (res.data) {
        navigate('/finish', { replace: true });
      } else {
        setButtonLoading(false);
        alert('Erro ao tentar enviar status. Tente novamente!');
      };
    })
    .catch(err => {
      setButtonLoading(false);
      alert('Erro ao tentar enviar status. Tente novamente!')
    });

    event.preventDefault();
  };

  const getLocationStatusComponent: Function = () => {
    switch (locationStatus) {
      case 'error':
        return (
          <Styles.LocationStatusSection style={{backgroundColor: 'var(--red)'}}>
            <Styles.CheckText style={{textAlign: 'center'}}> { 'Sua localização está desligada ou você não deu a permissão necessária' } </Styles.CheckText>
          </Styles.LocationStatusSection>
        );
    
      case 'loading':
        return (
          <span>loading</span>
        );

      case 'ready':
        return (
          <Styles.LocationStatusSection style={{backgroundColor: 'var(--green)'}}>
            <Styles.CheckCircleIcon size={24}/>
            <Styles.CheckText> { 'Acesso à localização ok' } </Styles.CheckText>
          </Styles.LocationStatusSection>
        );

      default:
        return (<></>);
    };
  };

  useEffect(() => {
    if (hash) {
      setLoading(true);

      protheusApi
      .get(`validahash?hash=${hash}`)
      .then(response => setAuthorizedStatus(response.data.statusHash))
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
    };
  }, [hash]);

  useEffect(() => {
    const id = location.search.substring(4);

    if (id) {
      setHash(id || '');
    };

  }, [location]);

  useEffect(() => {

    if (error) {
      setLocationStatus('error');
    } else if(loading) {
      setLocationStatus('loading');
    } else {
      console.log(position);
      setLocationStatus('ready');
    };

  }, [loading, error, position]);

  return isLoading ? <Loading color='var(--rancheiro-brown)' size={56}/> : !isAuthorized ? <PageNotFound /> : (
    <Styles.Container>
      <Header/>

      <Styles.Form onSubmit={event => handleSubmit(event)}>

        { getLocationStatusComponent() }

        <Styles.Label> { 'Selecione o status da viagem' } </Styles.Label>

        <Styles.DropDown
          required
          defaultValue=''
          onChange={event => setSelectedStatus(event.target.value)}
        >
          <Styles.ComboBoxOption disabled value=''> { 'Selecione um status' } </Styles.ComboBoxOption>
          {tripStatusOptions.map(option =>
            <Styles.ComboBoxOption
              key={option.id}
              value={option.id}
              disabled={locationStatus === 'error'}
            >
              {option.description}
            </Styles.ComboBoxOption>)}
        </Styles.DropDown>

        <Styles.Field
          placeholder='Informe sua situação atual'
          onChange={event => setStatusObs(event.target.value)}
        />

        <Styles.Button disabled={locationStatus !== 'ready'}> { buttonLoading ? <Loading size={32} color='var(--white)' /> : 'ENVIAR STATUS' } </Styles.Button>
      </Styles.Form>

      <SvgIllustration image={mapImage} alt='Imagem de delivery' />
      
      <Footer />
    </Styles.Container>
  );
};

export default Home;