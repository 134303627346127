import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CustomRoutes from './routes';

import GlobalStyle from './styles/global';

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <BrowserRouter>
        <CustomRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
