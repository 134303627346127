import React from 'react';

import * as Styles from './styles';

import rancheiroLogo from '../../assets/rancheiro.png';

const Header: React.FC = () => {
  return (
    <Styles.Container>
      <Styles.Logo src={rancheiroLogo} alt='Logo Café Rancheiro' />
      <Styles.Title> { 'Status da viagem' } </Styles.Title>
    </Styles.Container>
  );
};

export default Header;