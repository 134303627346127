import styled, { keyframes } from 'styled-components';
import { slideInRight, slideInLeft, slideInDown } from 'react-animations';

const slideRightAnimation = keyframes`${slideInRight}`;
const slideLeftAnimation  = keyframes`${slideInLeft}`;

export const Container = styled.section`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--white);
  color: var(--green);

  font-weight: bold;
  font-size: 2rem;
`;

export const NotFoundImage = styled.img`
  min-height: 20rem;
  max-height: 20rem;

  animation: .5s ${slideRightAnimation};
`;

export const MessageTitle = styled.h1`
  font-size: 2rem;

  margin-top: 3rem;
  
  animation: .5s ${slideLeftAnimation};
`;

export const MessageText = styled.span`
  font-size: 1.2rem;

  margin-top: 1rem;
  margin-bottom: 10rem;
  
  animation: .5s ${slideLeftAnimation};
`;