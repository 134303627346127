import styled from 'styled-components';

export const Container = styled.img`
  min-height: 20rem;
  max-height: 20rem;
  
  margin-top: auto;
  
  @media screen and (max-height: 800px) {
    min-height: 15rem;
    max-height: 15rem;
  }

  @media screen and (max-height: 600px) {
    display: none;
  }
`;
