import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 4rem;

  background-color: var(--rancheiro-brown);
  color: var(--white);

  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: space-around;

    padding: 2rem 1rem 1rem 1rem;
  }
`;

export const Logo = styled.img`
  min-height: 4rem;
  max-height: 4rem;

  @media screen and (max-width: 600px) {
    min-height: 10rem;
    max-height: 10rem;

    margin-bottom: 1rem;
  }
`;

export const Title = styled.h1`
  font-family: 'Montserrat', arial;
  font-size: 1.5rem;
  font-weight: bold;
`;