import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --white: #FFFFFF;
    --light: #E1E1E6;
    --grey: #E1E1E644;
    --medium: #222222;
    --dark: #111111;
    --red: #f44336;
    --google-red: #e16259;
    --green: #4CAF50;
    --yellow: #E03616;
    --purple: #7636ff;

    --rancheiro-light-yellow: #F8DE72;
    --rancheiro-medium-yellow: #F6D167;
    --rancheiro-brown: #402313;
    --rancheiro-black: #1F1F1F;
  }

  * {
    margin: 0;
    padding: 0;
    
    box-sizing: border-box;
    transition: 250ms;
    font-size: 16px;

    scrollbar-color: #f5f5f5 transparent;
    scrollbar-width: none;

    font-family: 'Roboto', sans-serif;
    
    @media screen and (max-width: 1690px) {
    }
    
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    
    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  html,
  body {
    background-color: var(--white);
    color: var(--dark);
    
    -webkit-font-smoothing: antialiased;
  }

  button,
  textarea,
  input,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ::-webkit-scrollbar {
    width: 0;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #f5f5f5;
    border-radius: 1.5rem;
  }
`;