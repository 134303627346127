import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: auto;

  background-color: var(--rancheiro-brown);
  color: var(--white);
`;
