import { useState, useEffect } from 'react';

interface Geolocation {
  latitude: number;
  longitude: number;
};

const requestAccessToUserLocation: Function = () => {
  return new Promise<Geolocation|string>((resolve, reject) => {
    const geolocation = window.navigator.geolocation;

    if (geolocation) {
      geolocation.getCurrentPosition(
        position => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        err => {
          switch (err.code) {
            case 0:
              reject('Erro desconhecido');
              break;
            
            case 1:
              reject('Usuário não deu permissão para coletar sua localização');
              break;
            
            case 2:
              reject('Não foi possível localizar o dispositivo');
              break;
            
            case 3:
              reject('Tempo de resposta excedido');
              break;
          }
        }
      );
    } else {
      reject('Navegador não suportado');
    };
  });
};

const useGeolocation: Function = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [position, setPosition] = useState<Geolocation>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    setLoading(true);

    requestAccessToUserLocation()
    .then((res: Geolocation) => setPosition(res))
    .catch((err: string) => setError(err))
    .finally(() => setLoading(false));
  }, []);

  return [loading, position, error];
};

export default useGeolocation;