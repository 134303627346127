import React from 'react';

import * as Styles from './styles';

import successImage from '../../assets/success.svg';

const Finish: React.FC = () => {
  return (
    <Styles.Container>
      <Styles.NotFoundImage
        src={successImage}
        alt='Imagem de status enviado com sucesso'
      />
      <Styles.MessageTitle> { 'Status enviado com sucesso!' } </Styles.MessageTitle>
      <Styles.MessageText> { 'Aguarde a próxima solicitação de status' } </Styles.MessageText>
    </Styles.Container>
  );
};

export default Finish;