interface FormattedDateAndTimeInterface {
  day: string;
  month: string;
  year: string;
  hours: string;
  minutes: string;
  seconds: string;
  completeDatetime: string;
  protheusDate: string;
  protheusTime: string;
};

function getFormattedDateAndTime(): FormattedDateAndTimeInterface {
  const date = new Date();

  const day: string = date.getDate().toString().padStart(2, '0');
  const month: string = (date.getMonth()+1).toString().padStart(2, '0');
  const year: string = date.getFullYear().toString();
  const hours: string = date.getHours().toString().padStart(2, '0');
  const minutes: string = date.getMinutes().toString().padStart(2, '0');
  const seconds: string = date.getSeconds().toString().padStart(2, '0');
  const completeDatetime: string = `${year}-${month}-${day}T${hours}:${minutes}`;
  const protheusDate: string = `${year}${month}${day}`;
  const protheusTime: string = `${hours}:${minutes}:${seconds}`;
  
  return {
    day,
    month,
    year,
    hours,
    minutes,
    seconds,
    completeDatetime,
    protheusDate,
    protheusTime
  };
};

export { getFormattedDateAndTime };