import React from 'react';

import * as Styles from './styles';

const Footer: React.FC = () => {
  return (
    <Styles.Container> { '2022 | Cafe Rancheiro Agro Industrial Ltda.' } </Styles.Container>
  );
};

export default Footer;