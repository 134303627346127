import React, { useEffect, useState } from 'react';

import * as Styles from './styles';
import useGeolocation from '../../utils/useGeolocation';

const Teste: React.FC = () => {
  const [loading, position, error] = useGeolocation();
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    if (error) {
      console.log(error);
      setTitle("ERROR");
      setContent(error);
    } else if(loading) {
      setTitle("LOADING");
    } else {
      console.log(position);
      setTitle("SUCCESS");
      setContent(`${position.latitude}, ${position.longitude}`);
    };

  }, [loading, error, position]);

  return (
    <Styles.Container>
      <Styles.MessageTitle> { title } </Styles.MessageTitle>
      <Styles.MessageText> { content } </Styles.MessageText>
    </Styles.Container>
  );
};

export default Teste;