import React from 'react';

import * as Styles from './styles';

import notFoundImageError from '../../assets/404.svg';

const PageNotFound: React.FC = () => {
  return (
    <Styles.Container>
      <Styles.NotFoundImage
        src={notFoundImageError}
        alt='Imagem de página não encontrada'
      />
      <Styles.MessageTitle> { 'Página não encontrada' } </Styles.MessageTitle>
      <Styles.MessageText> { 'Verifique se você leu o QR Code corretamente' } </Styles.MessageText>
    </Styles.Container>
  );
};

export default PageNotFound;